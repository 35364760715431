.dashboard__img-container {
    // width: 50px !important;
    // height: 50px;
    position: relative;
    background-color: $primary-color;
    padding: 15px;
    .mdi-icon:not(.account-edit__icon) {
        width: 100%;
        height: 100%;
    }
    &.edit-profile {
        cursor: pointer;
        transition: all .2s ease;
    }
    &.edit-profile:hover, &.edit-profile.active {
        background-color: $secondary-color;
    }
}
.dashboard__new-claim_info {
    opacity: 0;
    transition: all .2s ease;
    &.active {
        animation: flashing 2s linear infinite;
        opacity: 1;
    }
}
@keyframes flashing{
    0%{opacity: 1;}
    25%{opacity: .8;}
    50%{opacity: .3;}
    75%{opacity: .8;}
    100%{opacity: 1;}
    }
.dashboard__container {
    height: 100%;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: all .2s ease;
    &:hover{
        border-color: rgba(0,0,0,.08);
    } 
    &.contract.active {
        border-color: $secondary-color;
    }
    &.contract:hover .account-edit__icon {
        opacity: 1;
    }
    &.contract.active .account-edit__icon {
        opacity: 0 !important;
    }
}
.account-edit__icon {
    opacity: 0;
    color: white;
    position: absolute;
    top: 26px;
    right: 2px;
    transition: all .2s ease;
    animation: rotation 4s infinite linear;
}

.new-claim__button button {
    width: fit-content;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }