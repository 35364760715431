.selectHasError {
    .form__form-group-file{
    .file__placeholder{
        border-color: $color-error !important;
    }
    }
    .react-select__control {
    border: solid 1.5px $color-error !important; 
    }
}
.form__form-group-file {
    display: flex;
  
    label {
      width: 40%;
      padding: 4px 20px;
      cursor: pointer;
      transition: all 0.3s;
      text-align: center;
      height: 50px;
      font-weight: 600;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none !important;
      border: solid 2px $primary-color !important;
      // background-color: $primary-color !important;
      color: $primary-color;
      font-size: 1rem;
      &:hover {
        background-color: $primary-color !important;
        color: $primary-text;
      }
      &.uploaded {
        border: solid 1.5px $primary-color !important;
        color: #fff;
        background-color: $primary-color !important;
  
      }
  
  
      /*&:hover {
  
      }*/
    }
    .file__placeholder {
      width: 60%;
      display: flex;
      align-items: center;
      border: solid 1.5px $color-border;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      padding: 0 20px 0 10px;
      height: 50px;
      color:rgba(0, 0, 0, 0.54);
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.00938em;
      overflow: hidden;
      opacity: .8;
    }
  
    span {
      padding-left: 0;
      color: $primary-color;
      font-weight: 400;
    }
  
    input {
      display: none;
    }
  }
  