.button-group {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: 1.5rem auto
}

.secondary-btn.MuiButton-root.MuiButton-sizeMedium {
    &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.38) !important;
    }
    background-color: $primary-color !important;
    color: $primary-text !important;
    a {
        color: $primary-text !important;

    }
    
}
.primary-btn.MuiButton-root.MuiButton-sizeMedium {
    &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.38) !important;
    }
    &.edit-profile {
        background-color: $primary-color !important;
        &:hover {
            background-color: $secondary-color !important;

        }
    }
    background-color: $secondary-color !important;
    color: $primary-text !important;
    a {
        color: $primary-text !important;

    }
}
.secondary-btn {
    background-color: $secondary-color;
}