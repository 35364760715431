input:disabled, .Mui-disabled:not(label, p, span) {
    background-color: $disabled-color;
}

.bpost__container {
    position: relative;
    &::before {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: red;
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}