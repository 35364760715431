.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: $secondary-color !important;
}
.date-picker {
    .MuiOutlinedInput-input {
        pointer-events: none;
    }
}

.MuiDialog-scrollPaper {
    .MuiDialogActions-root {
        justify-content: space-between;
        padding: 16px 24px;
    }
    .MuiButton-root:nth-child(1) {
        background-color: $primary-color !important;
        color: white;
    }
    .MuiButton-root:nth-child(2) {
        background-color: $secondary-color !important;
        color: white;
    }
}
.form__form-group-input-wrap--error {
    color: #d32f2f;
    font-size: 0.75rem;
}
.error-text {
    display: block;
    margin-left: 14px;
    margin-top: 5px;
}
.form__form-group-input-wrap--error.error {
    .MuiInputLabel-root.MuiInputLabel-formControl {
        color: #d32f2f;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #d32f2f;
    }
}

/********** OLD, TextField based Material Datepicker **********/

// .form__form-group-datepicker {
//     padding: 16.5px 14px;
// }

// .form__form-group-input-wrap--error {
//     &.error {
//         .form__form-group-datepicker {
//             border-color: $color-error;
//         }
//     }
// }

// .react-datepicker__day--selected {
//     background-color: $primary-color !important;
//     &:hover{
//     background-color: lighten($primary-color, 10);
//     }
//     &:focus{
//     outline: none;
//     }
// }

// .react-datepicker__day {
//     &:hover {
//     background-color: lighten($primary-color, 10);
//     }
// }

// .react-datepicker__day--disabled{
//     &:hover{
//     color: #ccc;
//     background: transparent;
//     }
// }

// .react-datepicker__header {
//     background-color: $primary-color !important;
//     border-color: $primary-color;
//     border-radius: 0;
//     .react-datepicker__day-name {
//         color: #fff;
//     }
// }

// .react-datepicker__current-month, .react-datepicker-time__header,
// .react-datepicker__day-name, .react-datepicker__time-name {
//     color: #ffffff;
// }

// .react-datepicker__navigation--next {
//     display: none;
//     border-left-color: #ffffff;

//     &:hover {
//     border-left-color: #ffffff;
//     }
// }

// .react-datepicker__navigation--previous {
//     display: none;
//     border-right-color: #ffffff;

//     &:hover {
//     border-right-color: #ffffff;
//     }
// }

// .react-datepicker__month-read-view--selected-month{
//     color: #fff;
//     font-size: 1rem;
//     margin-right: 5px;
// }

// .react-datepicker__year-read-view--selected-year{
//     color: #fff;
//     font-size: 1rem;
//     margin-right: 5px;
// }

// .react-datepicker__current-month{
//     display: none;
// }

// .react-datepicker__year-option{
//     &:hover{
//     background-color: lighten($primary-color, 10);
//     }
// }
// .react-datepicker__year-option--selected_year{
//     color: #fff;
//     background-color: $primary-color;
// }

// .react-datepicker__month-option{
//     &:hover{
//     background-color: lighten($primary-color, 10);
//     }
// }
// .react-datepicker__month-option--selected_month{
//     color: #fff;
//     background-color: $primary-color;
// }

// .react-datepicker__day--keyboard-selected {
//     background-color: $primary-color !important;
//     &:hover{
//     background-color: lighten($primary-color, 10);
//     }
//     &:focus{
//     outline: none;
//     }
// }

// .react-datepicker__triangle {
//     border-bottom-color: $primary-color !important;
//     margin-top: -8px;
// }

// .date-picker{
//     width:100%;
//     .MuiFormControl-root {
//         width: 100%;
//     }
// }

// .react-datepicker-wrapper {
//     width: 100%
// }

// .form__form-group-datepicker{
//     width: 100%;
// }
