.contact__wrapper {
    background-color: rgba(0,0,0,.03);
}
.contact__info {
    background-color: $primary-color
}
.contact__info-box {
    background-color: white;
    width: fit-content;
    margin: auto;
    padding: 3rem;
}