$primary-color: #31444b;
$primary-border: 1px solid rgba(221, 221, 221, 0.8666666667);

$disabled-color: #f1f1f1;

$secondary-color: #e55431;
$secondary-text: #e55431;
$primary-text: white;

$tertiary-color: #DCF2F9;

$color-error: #B30000;
$color-success: #008000;
$color-warning: #F58221;
$color-border:#C1C1C3;
$color-additional: #999999;
$color-gray: #787985;
$color-light-gray: #d8dfe9;