.wizard__steps {
    padding: .6rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    @media(min-width: 768px) {
        width: 80%;
        padding: .6rem;
    }
    margin: auto;
    .wizard__step_wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        &:nth-last-child(1) {
            width: auto;
        }
        //left: 60px;
        &:nth-last-child(1)::after {
            display: none;
        }
        &::after {
            display: none;
            z-index: 9;
            position: absolute;
            content: '';
            height: 1px;
            top: calc(50% - 1px);
            left: 60px;
            background-color: $primary-color;
            width: calc(100% - 60px);
            @media(min-width: 576px) {
                display: block;
            }
        }
        &.active::after {
            background-color: $primary-color;
        }
    }
}
.wizard__step-container {
    z-index: 10;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $primary-color;
    border: 1px solid $primary-color;
    color: $primary-color;
    &.active {
        border-color: $primary-color;
        background-color: $primary-color;
        color: white;
    }
}